import axios from "axios";
import devModeCheck from "../Componets/Utilities/DevSettings";

const userHolder = JSON.parse(localStorage.getItem("user"));
var access_token = null;

if (userHolder !== null) {
  access_token = userHolder.token;
}

//Search Bar Fetch Statement
export async function getSearchBar(search) {
  console.log(search)
  return axios
    .get(`${devModeCheck("api")}/products/searchbar/${search}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

//Search Bar (Users) Fetch Statement
export async function getSearchBarUsers(search) {
  return axios
    .get(`${devModeCheck("api")}/users/trade/search/${search}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

//Home Page Products
export async function getHomeProducts() {
  return axios
    .get(`${devModeCheck("api")}/products/home/1`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function getInventoryProducts(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/admin/inventory`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  return response;
}

export async function getInstockProductsFromOneLocation(
  location,
  page,
  multiplier
) {
  const finalString = `{ "multiplier": 1, "page": 0, "locationID":69 }`;
  if (page > 0) {
    multiplier = 1;
  }

  const response = await fetch(
    `${devModeCheck(
      "api"
    )}/products/admin/getAllInStockProductsFromALocation?multiplier=${multiplier}&page=${page}&locationID=${location}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
    }
  );

  const json = await response.json();

  const hasNext = json.length < 50 * multiplier;

  if (multiplier > 1) {
    page = page + 1;
    page = page * multiplier;
  }

  return {
    NextPage: hasNext ? undefined : page + 1,
    data: json,
  };
}

// This is the search to get Products from either the main searchbar, or if they navigate to the page.
export function getAllProducts(name, page, set, multiplier) {
  if (set === "set") {
    if (page > 0) {
      multiplier = 1;
    }
    return axios
      .get(
        `${devModeCheck(
          "api"
        )}/products/set/${name}?page=${page}&multiplier=${multiplier}`,
        {
          headers: {
            "X-Auth-Token": access_token,
          },
        }
      )
      .then((res) => {
        const hasNext = res.data.length < 50 * multiplier;

        if (multiplier > 1) {
          page = page + 1;
          page = page * multiplier;

          return {
            NextPage: hasNext ? undefined : page + 1,
            data: res.data,
          };
        } else {
          return {
            NextPage: hasNext ? undefined : page + 1,
            data: res.data,
          };
        }
      });
  } else {
    if (name === undefined) {
      if (page > 0) {
        multiplier = 1;
      }
      return axios
        .get(
          `${devModeCheck(
            "api"
          )}/products/all/main?page=${page}&multiplier=${multiplier}`
        )
        .then((res) => {
          const hasNext = res.data.length < 50 * multiplier;

          if (multiplier > 1) {
            page = page + 1;
            page = page * multiplier;

            return {
              NextPage: hasNext ? undefined : page + 1,
              data: res.data,
            };
          } else {
            return {
              NextPage: hasNext ? undefined : page + 1,
              data: res.data,
            };
          }
        });
    } else {
      if (page > 0) {
        multiplier = 1;
      }
      return axios
        .get(`${devModeCheck("api")}/products/${name}?page=${page}`)
        .then((res) => {
          const hasNext = res.data.length < 50 * multiplier;

          if (multiplier > 1) {
            page = page + 1;
            page = page * multiplier;

            return {
              NextPage: hasNext ? undefined : page + 1,
              data: res.data,
            };
          } else {
            return {
              NextPage: hasNext ? undefined : page + 1,
              data: res.data,
            };
          }
        });
    }
  }
}

export function getAllSets() {
  return axios
    .get(`${devModeCheck("api")}/sets`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return { sets: res.data };
    });
}

export function getSingleSetName(finalString) {
  return axios
    .get(`${devModeCheck("api")}/sets/name/${finalString}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return { set: res.data[0] };
    });
}

export function getAllProductNames() {
  return axios
    .get(`${devModeCheck("api")}/products/all/names`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllProductsFilters(
  name,
  page,
  queryString,
  set,
  multiplier
) {
  if (set === "set") {
    if (page > 0) {
      multiplier = 1;
    }

    return axios
      .get(
        `${devModeCheck(
          "api"
        )}/products/filter/1?page=${page}&${queryString}&multiplier=${multiplier}`,
        {
          headers: {
            "X-Auth-Token": access_token,
          },
        }
      )
      .then((res) => {
        const hasNext = res.data.length < 50 * multiplier;

        if (multiplier > 1) {
          page = page + 1;
          page = page * multiplier;

          return {
            NextPage: hasNext ? undefined : page + 1,
            filteredData: res.data,
          };
        } else {
          return {
            NextPage: hasNext ? undefined : page + 1,
            filteredData: res.data,
          };
        }
      });
  } else {
    if (name === undefined) {
      if (page > 0) {
        multiplier = 1;
      }
      return axios
        .get(
          `${devModeCheck(
            "api"
          )}/products/filter/1?page=${page}&${queryString}&multiplier=${multiplier}`
        )
        .then((res) => {
          const hasNext = res.data.length < 50 * multiplier;

          if (multiplier > 1) {
            page = page + 1;
            page = page * multiplier;

            return {
              NextPage: hasNext ? undefined : page + 1,
              filteredData: res.data,
            };
          } else {
            return {
              NextPage: hasNext ? undefined : page + 1,
              filteredData: res.data,
            };
          }
        });
    } else {
      if (page > 0) {
        multiplier = 1;
      }
      return axios
        .get(
          `${devModeCheck(
            "api"
          )}/products/filter/${name}?page=${page}&${queryString}&multiplier=${multiplier}`
        )
        .then((res) => {
          const hasNext = res.data.length < 50 * multiplier;

          if (multiplier > 1) {
            page = page + 1;
            page = page * multiplier;

            return {
              NextPage: hasNext ? undefined : page + 1,
              filteredData: res.data,
            };
          } else {
            return {
              NextPage: hasNext ? undefined : page + 1,
              filteredData: res.data,
            };
          }
        });
    }
  }
}

export async function updateInventory(productID, finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/update/${productID}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export async function updateInventoryAdmin(productID, finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/update/admin/${productID}`,

    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export async function updateUser(userID, finalString) {
  const response = await fetch(`${devModeCheck("api")}/users/${userID}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });

  const json = await response.json();

  return json;
}

export async function deleteUser(userID, finalString) {
  const response = await fetch(`${devModeCheck("api")}/users/${userID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });

  const json = await response.json();

  return json;
}

export async function deleteUserAdmin(userID) {
  console.log(userID)
  const response = await fetch(`${devModeCheck("api")}/users/admin/delete/${userID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
  });

  const json = await response.json();

  return json;
}

export function getSingleProduct(productID) {
  return axios
    .get(`${devModeCheck("api")}/products/single/${productID}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return { products: res.data };
    });
}

export function getTransactionProducts(finalString) {
  if (finalString === undefined) {
    return { products: [] };
  } else {
    return axios
      .get(`${devModeCheck("api")}/products/transaction/1?${finalString}`, {
        headers: {
          "X-Auth-Token": access_token,
        },
      })
      .then((res) => {
        return { products: res.data };
      });
  }
}

export function getTradeUsers() {
  return axios
    .get(`${devModeCheck("api")}/users/trade`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getTradeProducts(name) {
  return axios
    .get(`${devModeCheck("api")}/products/trade/${name}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getCustomerEmail(email) {
  return axios
    .get(`${devModeCheck("api")}/users/trade/${email}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getTransaction(id) {
  return axios
    .get(`${devModeCheck("api")}/orders/${id}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function createTransaction(finalString) {
  const response = await fetch(`${devModeCheck("api")}/orders/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });

  const content = await response.json();

  return content;
}

export async function updateTransaction(finalString, id) {
  const response = await fetch(`${devModeCheck("api")}/orders/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });

  return response;
}

export function getTradeOrders(orderStatus, page) {
  return axios
    .get(`${devModeCheck("api")}/orders/trade/${orderStatus}?page=${page}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      const hasNext = res.data.length < 50;

      return {
        NextPage: hasNext ? undefined : page + 1,
        data: res.data,
      };
    });
}

export function getBuyListOrders(orderStatus, page) {
  return axios
    .get(`${devModeCheck("api")}/orders/buyList/${orderStatus}?page=${page}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSalesOrders(orderStatus, page) {
  return axios
    .get(`${devModeCheck("api")}/orders/sales/${orderStatus}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSingleOrders(_id) {
  return axios
    .get(`${devModeCheck("api")}/orders/${_id}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSingleUserOrders(orderType, id, page) {
  return axios
    .get(
      `${devModeCheck("api")}/orders/account/${orderType}/${id}?page=${page}`,
      {
        headers: {
          "X-Auth-Token": access_token,
        },
      }
    )
    .then((res) => {
      return res.data;
    });
}

export function getSingleUser(id) {
  return axios
    .get(`${devModeCheck("api")}/users/${id}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getTaxRates() {
  return axios
    .get(`${devModeCheck("api")}/taxRates/`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSinglePromoCode(code) {
  return axios
    .get(`${devModeCheck("api")}/promos/code/${code}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getSinglePromoCode_id(id) {
  return axios
    .get(`${devModeCheck("api")}/promos/${id}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function updatePromoUserSubtract(finalString, id) {
  const response = await fetch(
    `${devModeCheck("api")}/promos/userSubtract/${id}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  return response;
}

export async function updatePromoUserAdd(finalString, id) {
  const response = await fetch(`${devModeCheck("api")}/promos/userAdd/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });
  return response;
}

export async function updatePromoAdmin(finalString, id) {
  const response = await fetch(`${devModeCheck("api")}/promos/admin/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });
  return response;
}

export function getSinglePromoEmail(email) {
  return axios
    .get(`${devModeCheck("api")}/promos/email/${email}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function createPromo(finalString) {
  const response = await fetch(`${devModeCheck("api")}/promos/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });

  const content = await response.json();

  return content;
}

export async function sendEmail(type, finalString) {
  const response = await fetch(`${devModeCheck("api")}/email/send/${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": access_token,
    },
    body: finalString,
  });
  return response;
}

export function getVerifyEmail(id, token) {
  return axios
    .get(`${devModeCheck("api")}/users/${id}/verify/${token}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getVerifyTokenForgotPassword(id, token) {
  return axios
    .get(`${devModeCheck("api")}/users/${id}/forgotPassword/${token}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function updateForgotPassword(id, token, finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/users/${id}/forgotPassword/${token}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export async function sendForgotPasswordEmail(finalString, email) {
  return axios
    .get(`${devModeCheck("api")}/users/forgotPassword/request/${email}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function updatePassword(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/users/accountUpdate/updateWord`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export async function verifyCaptchaToken(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/users/captcha/verify-token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export function getSingleUserLogin(id, token) {
  return axios
    .get(`${devModeCheck("api")}/users/${id}`, {
      headers: {
        "X-Auth-Token": token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function updateUserLogin(userID, finalString, token) {
  const response = await fetch(`${devModeCheck("api")}/users/${userID}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
    body: finalString,
  });

  const json = await response.json();

  return json;
}

export function getAllRewardItems() {
  return axios
    .get(`${devModeCheck("api")}/rewardItems`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getInventoryTotals() {
  return axios
    .get(`${devModeCheck("api")}/products/inventoryTotal/all`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getPriceUpdates(set) {
  return axios
    .get(`${devModeCheck("api")}/products/priceUpdate/Pokemon/${set}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getPriceUpdatesV2(letter) {
  return axios
    .get(`${devModeCheck("api")}/products/autoPriceUpdate/v2/Pokemon/${letter}`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}


export async function autoUpdateCardPrices(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/priceUpdate/Pokemon/update`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();

  return json;
}

export function getLocations() {
  return axios
    .get(`${devModeCheck("api")}/locations/`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function moveFromOneInventoryToAnother(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/admin/moveInventory`,

    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

export async function moveAllInventoryFromOneInventoryToAnother(finalString) {
  const response = await fetch(
    `${devModeCheck("api")}/products/admin/moveInventory/all`,

    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}

//Get All Users Fetch Statement
export async function getAllUsers() {
  return axios
    .get(`${devModeCheck("api")}/users/`, {
      headers: {
        "X-Auth-Token": access_token,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function updateUserRoleChange(id,finalString) {
  console.log(id)
  console.log(finalString)
  const response = await fetch(
    `${devModeCheck("api")}/users/roleChange/admin/${id}`,

    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": access_token,
      },
      body: finalString,
    }
  );

  const json = await response.json();
  return json;
}